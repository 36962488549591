<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">

        <span>组织架构：</span>
        <!-- <el-cascader ref="firstCascader" :options="ckdTreeData" :props="defaultProps" v-model="class_id"
          @change="changeDate" :show-all-levels="false" clearable placeholder="请选择" filterable
          style="width: 150px;margin-right:10px" size="small">
        </el-cascader> -->
        <tchtree v-model="class_id" @change="changeDate"></tchtree>
        <span style="margin-left:20px">选择周：</span>
        <!--        <el-date-picker  @change="getData" v-model="sign_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">-->
        <!--        </el-date-picker>-->
        <el-date-picker @change="changeDate" size="small" v-model="year_week" type="week" range-separator="~"
          format="yyyy 第 W 周" :picker-options="{ firstDayOfWeek: 1 }" placeholder="选择周">
        </el-date-picker>


      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>学生周报</el-breadcrumb-item>
          <el-breadcrumb-item>周报统计</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent" style="width: 100%;height: calc( 100% - 80px );">
      <div style="text-align:right;padding-right:50px">
        <el-button @click="setDataType1" size="small"
          :icon="dataType1 == 'chart' ? 'el-icon-notebook-2' : 'el-icon-data-analysis'">{{ dataType1 == 'chart' ? '表格查看' :
            '图表查看'
          }}</el-button>

      </div>
      <div v-show="dataType1 == 'chart'" id="chartBox" :style="sessionArr.length < 2 ? 'height:70%;' : 'height:500px;'"></div>
      <div v-show="dataType1 == 'chart' && !isClassLeader" id="chartBox_2" style="width: 100%;height: 500px;"></div>
      <div v-show="dataType1 == 'xls'" style="padding:50px">
        <!-- <el-button @click="downXls" size="small" >下载数据表</el-button>
       -->
        <table class="dttable" id="dktable">
          <tr>
            <td class="tdheader">序号</td>
            <td class="tdheader">班级名称</td>
            <td class="tdheader">班主任</td>
            <td class="tdheader">系部</td>
            <td class="tdheader">提交率</td>
          </tr>

          <tr v-for="(item, idx) in dataList" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>{{ item.class_name }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.yx_name }}</td>
            <td>{{ item.tj_rate }}%</td>
          </tr>

        </table>

      </div>

      <div style="text-align:right;padding-right:50px">
        <el-button @click="setDataType2" size="small"
          :icon="dataType2 == 'chart' ? 'el-icon-notebook-2' : 'el-icon-data-analysis'">{{ dataType2 == 'chart' ? '表格查看' :
            '图表查看'
          }}</el-button>

      </div>

      <div v-show="dataType2 == 'chart'" id="chartBox1" :style="sessionArr.length < 2 ? 'height:70%;' : 'height:500px;'"></div>
      <div v-show="dataType2 == 'chart' && !isClassLeader" id="chartBox1_2" style="width: 100%;height: 500px;"></div>
      <div v-show="dataType2 == 'xls'" style="padding:50px">
        <!-- <el-button @click="downXls" size="small" >下载数据表</el-button>
       -->
        <table class="dttable" id="dktable">
          <tr>
            <td class="tdheader">序号</td>
            <td class="tdheader">班级名称</td>
            <td class="tdheader">班主任</td>
            <td class="tdheader">系部</td>
            <td class="tdheader">审批率</td>
          </tr>

          <tr v-for="(item, idx) in dataList1" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>{{ item.class_name }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.yx_name }}</td>
            <td>{{ item.sp_rate }}%</td>
          </tr>

        </table>

      </div>
    </div>



  </div>
</template>

<script>
import util from "../../../../utils/util.js"
import tchtree from '../../../com/tchTree.vue'
export default {
  components: {
    tchtree
  },
  data() {
    return {
      myClasses: [],
      class_id: "",
      sign_date: util.formatDate(new Date()),
      dataList: [],
      dataList1: [],
      year_week: '',
      report_year: '',
      week: '',
      weekStart: "",
      weekEnd: "",
      ckdTreeData: [],

      defaultProps: {
        label: 'name',
        value: 'id',
        children: 'children',
        emitPath: false,
        checkStrictly: true
      },
      dataType1: "chart",
      dataType2: "chart",
      isClassLeader: false,
      sessionArr: [],

    }
  },
  mounted() {
    this.getConfig()
    this.getMyClasses1()
    // $("#chartBox").height(($(window).height() - 220) / 2)
    // $("#chartBox_2").height(($(window).height() - 220) / 2)
    // $("#chartBox1").height(($(window).height() - 220) / 2)
    // $("#chartBox1_2").height(($(window).height() - 220) / 2)
    this.getTree()
    this.getNowDate()
  },
  methods: {
    getMyClasses1() {

      this.$http.post("/api/get_teacher_info").then(res => {
        let bzr = []
        if (res.data.class_names && res.data.class_names.length > 0) {
          for (let item of res.data.class_names) {
            if (item.type == "班主任") {
              this.isClassLeader = true
            }
          }
        }


      })
    },
    setDataType1() {
      if (this.dataType1 == "chart") {
        this.dataType1 = "xls"
      } else {
        this.dataType1 = "chart"
      }
    },
    setDataType2() {
      if (this.dataType2 == "chart") {
        this.dataType2 = "xls"
      } else {
        this.dataType2 = "chart"
      }
    },
    getMyClasses() {

      this.$http.post("/api/get_tch_cls_data").then(res => {
        this.myClasses = [].concat(res.data.bj_list).concat(res.data.sxxz_list)
        // this.class_id = this.myClasses[0].id
        this.getNowDate()
      })
    },
    //获取届次518、519
    getConfig() {
      this.$http.post("/api/sys_sch_config_list", { ktype: "ktype", keyword: 'tongji_jieci' }).then(res => {
        if (res.data.data && res.data.data[0]) {
          if (res.data.data[0].kvalue && res.data.data[0].kvalue.indexOf(',')) {
            this.sessionArr = res.data.data[0].kvalue.split(',')
          } else {
            this.sessionArr = [res.data.data[0].kvalue]
          }
        }

      })
    },
    getTree() {

      this.$http.post("/api/sys_office_tree", { parent_id: 0, biye: this.biye }).then(res => {
        this.treeData = res.data.tree
        this.ckdTreeData = this.treeData
      })
    },
    getNowDate() {
      this.$http.post("/api/find_date").then(d => {
        this.report_year = d.data.year
        this.week = d.data.week
        this.year_week = new Date(d.data.this_date.split(' ')[0])
        this.weekStart = d.data.onMonday.split(' ')[0]
        this.weekEnd = d.data.sunday.split(' ')[0]
        this.getData()
        this.getData1()
      })
    },
    changeDate(e) {

      let str = this.$moment(this.year_week).utcOffset(480).format("yyyy-WW")

      this.weekStart = this.$moment(this.year_week).utcOffset(480).format("yyyy-MM-DD")
      this.weekEnd = this.$moment(this.year_week).add(6, "day").utcOffset(480).format("yyyy-MM-DD")

      let arr = str.split('-')
      this.report_year = arr[0]
      this.week = arr[1]
      this.getData()
      this.getData1()
    },
    changeClass(e) {
      this.getData()
      this.getData1()
    },
    getData() {
      this.$http.post("/api/stu_week_statistics", { report_year: this.report_year, week: this.week, start: this.weekStart, end: this.weekEnd, class_id: this.class_id }).then(res => {
        res.data && res.data.map(a => {
          if (a.tj_rate) {
            a.tj_rate = this.$keepTwoDecimal(parseFloat(a.tj_rate) * 100)
          }
          a.class_name = a.NAME
          a.NAME += a.username
        })
        this.dataList = res.data
        if (this.sessionArr && this.sessionArr.length > 1) {
          this.initChart()
          this.initChart_2()
        } else {
          this.initChart()
        }
      })
    },
    getData1() {
      this.$http.post("/api/stu_sp_week_statistics", { report_year: this.report_year, week: this.week, class_id: this.class_id }).then(res => {
        res.data && res.data.map(a => {
          if (a.sp_rate) {
            a.sp_rate = this.$keepTwoDecimal(parseFloat(a.sp_rate) * 100)
          }
          a.class_name = a.NAME
          a.NAME += a.username
        })
        this.dataList1 = res.data

        if (this.sessionArr && this.sessionArr.length > 1) {
          this.initChart1()
          this.initChart1_2()
        } else {
          this.initChart1()
        }
      })
    },
    initChart() {
      let dataList = [];//过滤519的
      if (this.sessionArr && this.sessionArr.length > 0 && this.sessionArr[0]) {
        for (let item of this.dataList) {
          if (item.class_name.indexOf(this.sessionArr[0]) >= 0) {
            dataList.push(item)
          }
        }
      } else {
        dataList = this.dataList
      }
      let dataAxis = this._lo.map(dataList, 'NAME')
      let data = this._lo.map(dataList, 'tj_rate')


      let option = {
        title: {
          text: '班级/小组周报提交率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {

            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },
        },
        xAxis: {
          data: dataAxis,
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10
        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
                            color: '#33a9ff',

              // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: '#83bff6' },
              //   { offset: 0.5, color: '#188df0' },
              //   { offset: 1, color: '#188df0' }
              // ])
            },
            emphasis: {
              itemStyle: {
                              color: '#33a9ff',

                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   { offset: 0, color: '#2378f7' },
                //   { offset: 0.7, color: '#2378f7' },
                //   { offset: 1, color: '#83bff6' }
                // ])
              }
            },
            data: data,
            label: {
              show: true,
              position: 'inside',
              // formatter: function (params) { //标签内容
              //   return params.value + '%'
              // },
            },
          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('chartBox'));
      myChart.setOption(option)

    },
    initChart_2() {
      let dataList = [];//过滤518的
      if (this.sessionArr && this.sessionArr.length > 1 && this.sessionArr[1]) {
        for (let item of this.dataList) {
          if (item.class_name.indexOf(this.sessionArr[1]) >= 0) {
            dataList.push(item)
          }
        }
      } else {
        dataList = this.dataList
      }
      let dataAxis = this._lo.map(dataList, 'NAME')
      let data = this._lo.map(dataList, 'tj_rate')


      let option = {
        title: {
          text: '班级/小组周报提交率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'line' // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function (params) {

            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },
        },
        xAxis: {
          data: dataAxis,
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10
        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: '#33a9ff'
              /*color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])*/
            },
            emphasis: {
              itemStyle: {
                color: '#33a9ff',
                /*color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])*/
              }
            },
            data: data,
            label: {
              show: true,
              position: 'inside',
              formatter: function (params) { //标签内容
                return params.value + '%'
              },
            },
          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('chartBox_2'));
      myChart.setOption(option)

    },
    initChart1() {
      let dataList1 = [];//过滤519的
      if (this.sessionArr && this.sessionArr.length > 0 && this.sessionArr[0]) {
        for (let item of this.dataList1) {
          if (item.class_name.indexOf(this.sessionArr[0]) >= 0) {
            dataList1.push(item)
          }
        }
      } else {
        dataList1 = this.dataList1
      }
      let dataAxis = this._lo.map(dataList1, 'NAME')
      let data = this._lo.map(dataList1, 'sp_rate')


      let option = {
        title: {
          text: '班级/小组周报审核率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',

          formatter: function (params) {

            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },

        },
        xAxis: {
          data: dataAxis,
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10
        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
              formatter: function (params) { //标签内容
                return params.value + '%'
              },
            },
          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('chartBox1'));
      myChart.setOption(option)

    },
    initChart1_2() {
      let dataList1 = [];//过滤518的
      if (this.sessionArr && this.sessionArr.length > 1 && this.sessionArr[1]) {
        for (let item of this.dataList1) {
          if (item.class_name.indexOf(this.sessionArr[1]) >= 0) {
            dataList1.push(item)
          }
        }
      } else {
        dataList1 = this.dataList1
      }
      let dataAxis = this._lo.map(dataList1, 'NAME')
      let data = this._lo.map(dataList1, 'sp_rate')


      let option = {
        title: {
          text: '班级/小组周报审核率(%)',
        },
        grid: { containLabel: true, },
        tooltip: {
          trigger: 'axis',

          formatter: function (params) {

            if (params.length > 0) {
              var result = params[0].name + "<br>";
              params.forEach(function (item) {
                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}%</br>`
              });
              return result;
            }
          },

        },
        xAxis: {
          data: dataAxis,
          axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
          axisTick: { show: true },
          axisLine: { show: true },
          z: 10
        },
        yAxis: {
          max: 100,//最大值
          min: 0,//最小值
          interval: 10,//间隔
          axisLine: { show: true },
          axisTick: { show: true },
          axisLabel: { color: '#000' }
        },
        series: [
          {
            type: 'bar',
            barMaxWidth: 30,
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
            emphasis: {
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            },
            data: data,

            label: {
              show: true,
              position: 'inside',
              formatter: function (params) { //标签内容
                return params.value + '%'
              },
            },
          }
        ],
      };

      let myChart = this.$echarts.init(document.getElementById('chartBox1_2'));
      myChart.setOption(option)

    }

  },

};
</script>

